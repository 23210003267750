import type { RenderLoader, CreateStartOptions } from '../types'

interface Options extends CreateStartOptions { }

export default function lazyMicroservices(renderLoader: RenderLoader, mountPointId: string, options: Options) {
  let preventLoading = false

  const fn = async () => {
    if (preventLoading) {
      return
    }

    preventLoading = true

    await (await options.lazyStoreManagerLoader()).initializing

    const renderer = renderLoader()

    let mountPoint = document.getElementById(mountPointId)

    if (!mountPoint) {
      mountPoint = document.createElement('div')
      mountPoint.id = mountPointId
      document.body.appendChild(mountPoint)
    }

    (await renderer).render(mountPoint)
  }

  if (localStorage.getItem('_ms_lazyloaded') === 'true') {
    fn()
  } else {
    window.addEventListener('mousemove', load)
    window.addEventListener('touchstart', load)
    window.addEventListener('scroll', load)

    window.document.addEventListener('click', handleClick)
  }

  async function load() {
    window.removeEventListener('mousemove', load)
    window.removeEventListener('touchstart', load)
    window.removeEventListener('scroll', load)

    localStorage.setItem('_ms_lazyloaded', 'true')

    await fn()
  }

  async function handleClick(e: Event) {
    let target = e.target as HTMLElement

    while (target && target?.dataset?.openAuthModal !== 'true') {
      target = target.parentNode as HTMLElement
    }

    if (!target) {
      return
    }

    window.document.removeEventListener('click', handleClick)

    await fn()

    setTimeout(() => {
      target.click()
    }, 80)
  }
}
